<template>
    <div>
        <v-btn color="green darken-1" dark fab fixed bottom left @click="dialog = true">
            <v-icon>mdi-refresh</v-icon>
        </v-btn>
        <add-new :settings="addMore" v-if="addMore.active"></add-new>
        <v-dialog v-model="dialog" persistent max-width="290">
            <v-card>
                <v-card-title class="headline">Warning!</v-card-title>
                <v-card-text>Are your sure you want to start again? You will loose everything in your cart.</v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="red darken-1" text @click="restart()">Yes</v-btn>
                    <v-btn color="green darken-1" text @click.native="dialog = false">No</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import AddNew from '@/components/AddNew.vue';
import { mapGetters, mapActions } from 'vuex';

export default {
    props: {
        s: { type: Object, default: () => {} }
    },
    data() {
        return {
            dialog  : false,
            addMore : this.s,
        }
    },
    computed: {
        ...mapGetters({
            store: 'store'
        })
    },
    methods: {
        ...mapActions({
            store       : 'store',
            clearStore  : 'clearStore',
            updateStore : 'updateStore'
        }),
        restart() {
            let currentStore = this.store.store;
            let isClubman = this.store.isClubman;
            this.clearStore();
            this.updateStore({
                prop    : 'store',
                payload : currentStore
            });
            this.updateStore({
                prop    : 'isClubman',
                payload : isClubman
            })

            this.dialog = false;
            this.$router.push('/');
        }
    },
    components:{
        AddNew
    }
}
</script>

<style lang="scss" scoped>

</style>